(function(angular) {
    'use strict';

    angular.module("app")
        .filter('contrastColor', contrastColor);

    function contrastColor() {
        return function(backgroundColor) {
            return getContrastLum(hexToRGB(parseInt(backgroundColor, 16)));
        }
    }

    function hexToRGB(hex){
        var r = hex >> 16;
        var g = hex >> 8 & 0xFF;
        var b = hex & 0xFF;
        return [r,g,b];
    }

    function getContrastLum(rgb) {
        var luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];

        return luminance < 110 ? 'fff' : '000';
    }
}(angular));